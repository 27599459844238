import React, { createContext, useContext, useState } from 'react';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firebase-database';
import 'firebase/firebase-storage';
import 'firebase/firebase-functions';
import 'firebase/firestore';

import 'firebase/analytics';
import store from 'store2';

import firebaseSettings from 'core/firebase/settings';

import getAccountID from '../util/getAccountID';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const FirebaseContext = createContext();
const defaultApp = app.initializeApp(firebaseSettings);
const dbApp = app.initializeApp(firebaseSettings, 'default');

const firestoredb = dbApp.firestore();
const firestoreTimeStamp = app.firestore.FieldValue.serverTimestamp();

const auth = defaultApp.auth();
const defaultDb = dbApp.database();

const storage = dbApp.storage().ref();
const photoStorage = dbApp.storage(
  process.env.REACT_APP_PHOTOS_STORAGE || 'galapp-web-fotos'
);
const fileStorage = dbApp.storage(
  process.env.REACT_APP_FILES_STORAGE || 'galapp-web-archivos'
);

const downloadsStorage = (bucket) => {
  return dbApp.storage(bucket);
};


function FirebaseProvider(props) {
  const [user, setUser] = useState(null);
  const [userM, setUserM] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [menuConfig, setMenuConfig] = useState({});

  const [nameAccount, setNameAccount] = useState('');
  const [isInitialized, setInitialize] = useState(false);
  const [userDB, setUserDB] = useState(null);


  const [isNewUserRegistered, setIsNewUserRegistered] = useState(false);


  /**
   * Inicializa la aplicación y configura la base de datos
   * con base al usuario actual.
   * @param {Object} currentUser
   */

  const initialize = async (currentUser) => {
    try {
      const lastAccount = store.get('lastAccount');
      const snapshot = await defaultDb
        .ref(`usuarios/${currentUser.uid}`)
        .once('value');

      const userData = snapshot.val();
      if (!userData) {
        setUserM(currentUser);
        setUser(null);
        setInitialize(true);
      }

      const userDB = await getCurrentDB(lastAccount ? lastAccount : userData);

      let currentUserAccountID = getAccountID(
        lastAccount ? lastAccount.nodo_raiz : userData.nodo_raiz,
        lastAccount ? lastAccount.base_datos : userData.base_datos
      );

      if (!(currentUserAccountID in userData['cuentas'])) {
        currentUserAccountID = userData['cuentas'][0];
      }
      let userState = {
        ...currentUser,
        profile: userData['cuentas'][currentUserAccountID].perfil,
        userForms: [],
        userDb: userData['cuentas'][currentUserAccountID].base_datos,
        rootNode: userData['cuentas'][currentUserAccountID].nodo_raiz,
        superset_activo:
          userData['cuentas'][currentUserAccountID].superset_activo,
        superset_url: userData['cuentas'][currentUserAccountID].superset_url,
        url_calendario:
          userData['cuentas'][currentUserAccountID].url_calendario,
        accounts: userData['cuentas'],
        currentUserAccountID: currentUserAccountID,
      };

      setInitialize(true);
      setUser(userState);
      setUserDB(userDB);

      const nameCurrentAccount =
        userData['cuentas'][currentUserAccountID].nombre;
      setNameAccount(nameCurrentAccount);

      const configSnapshot = await userDB.ref('config/').once('value');
      const dbConfig = configSnapshot.val();

      setMenuConfig({
        menuPDF: dbConfig ? dbConfig.archivos_pdf : false,
        menuAdmForm: administadorFormularios(userState) && true,
        menuadm:
          userData['cuentas'][currentUserAccountID].admin_cuenta === 'Si'
            ? true
            : false,
        menuAnalytics: userState.superset_activo ? true : false,
        isCalendarEnabled: dbConfig && dbConfig.exportar_calendario,
        mfa_requerido: dbConfig && dbConfig.mfa_requerido,
        fecha_servidor: dbConfig && dbConfig.fecha_servidor
      });
    } catch (error) {
      logout();
      throw new Error(error);
    }
  };

  if (!isInitialized && !user) {
    dbApp.auth().onAuthStateChanged(function (user) {
      if (user) {
        setIsAuthenticated(true);
        !isInitialized && initialize(user);
      } else {
        setIsAuthenticated(false);
        setUser(null);
        setInitialize(true);
      }
    });
  }

  // se obtiene bd segun usuario actual
  const getCurrentDB = async (currentUser) => {
    let currentDB;
    let currentDatabase = dbApp.database(
      `https://${currentUser.base_datos}.firebaseio.com/`
    );

    if (currentUser.nodo_raiz) {
      currentDB = {
        ...currentDatabase,
        ref: (requestRef) => {
          return currentDatabase.ref(`${currentUser.nodo_raiz}/${requestRef}`);
        },
      };
    } else {
      currentDB = currentDatabase;
    }
    return currentDB;
  };

  const handleReauthenticateUser = async () => {
    dbApp.auth().signOut();
    setUser(null);
    setIsAuthenticated(false);
  };

  //Cambio de cuenta
  const changeAccountAdm = async (currentUser) => {
    store.set('lastAccount', currentUser);

    try {
      let userState = {
        ...user,
        profile: currentUser.perfil,
        userForms: [],
        userDb: currentUser.base_datos,
        rootNode: currentUser.nodo_raiz,
        superset_activo: currentUser.superset_activo,
        superset_url: currentUser.superset_url,
        currentUserAccountID: getAccountID(
          currentUser.nodo_raiz,
          currentUser.base_datos
        ),
      };

      const userDB = await getCurrentDB(currentUser);
      const configSnapshot = await userDB.ref('config/').once('value');
      const dbConfig = configSnapshot.val();

      const menuConfigAccountSelec = {
        ...menuConfig,
        menuadm: currentUser.admin_cuenta === 'Si' ? true : false,
        menuAdmForm:
          currentUser.admin_cuenta === 'Si' && administadorFormularios(user)
            ? true
            : false,
        menuAnalytics: currentUser.superset_activo,
        menuPDF: dbConfig ? dbConfig.archivos_pdf : false,
        isCalendarEnabled: dbConfig && dbConfig.exportar_calendario,
        mfa_requerido: dbConfig && dbConfig.mfa_requerido,
        fecha_servidor: dbConfig && dbConfig.fecha_servidor,
      };

      let mfaAuthenticated;
      const mfaCookie = Cookies.get('8s9t1');
      if (mfaCookie) {
        const decryptedBytes = CryptoJS.AES.decrypt(
          mfaCookie,
          'mfa_key_authentication'
        );
        mfaAuthenticated = decryptedBytes.toString(CryptoJS.enc.Utf8);
      }

      if (menuConfigAccountSelec.mfa_requerido && !mfaAuthenticated) {
        store.set('lastAccount', currentUser);
        // setUser(null);
        return menuConfigAccountSelec.mfa_requerido;
      } else {
        setUser(userState);
        setUserDB(userDB);
        setMenuConfig(menuConfigAccountSelec);
        return false;
      }
    } catch (error) {
      //error;
    }
  };

  //Actualizacion del nombre de la cuenta activa
  const nameAccountBD = async (currentUser) => {
    try {
      const BDNR = await accountID(currentUser);
      const snapshot = await defaultDb
        .ref(`usuarios/${currentUser.uid}/cuentas/${BDNR}`)
        .once('value');
      const userData = snapshot.val();
      return setNameAccount(userData.nombre);
    } catch (error) {
      return setNameAccount('');
    }
  };

  //Construccion de la cuenta base_datos + nodo_raiz
  const accountID = async (currentUser) => {
    try {
      let cuentasDB = currentUser.userDb;

      if (currentUser.rootNode) {
        const transf = currentUser.rootNode.replace('/', '>');
        cuentasDB = currentUser.userDb + '@' + transf;
      }
      return cuentasDB;
    } catch (error) {
      //error
    }
  };

  //Habilita el administrador de formularios a los usuarios de Galápago Agroconsultores
  const administadorFormularios = (userAF) => {
    return (
      userAF &&
      'emailVerified' in userAF &&
      userAF.emailVerified === true &&
      userAF.email.includes('@galapagoagro.co')
    );
  };
  //Vista del menu si es administrador de la cuenta
  const menuUserAdm = async (userState) => {
    try {
      const BDNR = await accountID(userState);
      const snapshot = await defaultDb
        .ref(`cuentas/${BDNR}/usuarios`)
        .once('value');
      const userData = snapshot.val();
      const userid = userState.uid;

      const result = userData[userid];

      setMenuConfig({
        ...menuConfig,
        menuadm: result.admin_cuenta === 'Si' ? true : false,
        menuAdmForm:
          result.admin_cuenta === 'Si' && administadorFormularios(userState)
            ? true
            : false,
      });
    } catch (error) {
      setMenuConfig({ ...menuConfig, menuadm: false, menuAdmForm: false });
    }
  };

  // lista de cuentas desde el nodo cuentas
  const listAccount = async (currentUser) => {
    try {
      //BdNr basedatos nodoraiz
      const BDNR = await accountID(currentUser);
      const snapshot = await defaultDb.ref(`cuentas/${BDNR}`).once('value');
      const userData = snapshot.val();

      const bdcuentasusuarios = userData.usuarios;
      return bdcuentasusuarios;
    } catch (error) {
      //error
    }
  };

  // lista de cuentas desde el nodo usuario/uid
  const userAccounts = async (currentUser) => {
    try {
      const snapshot = await defaultDb
        .ref(`usuarios/${currentUser.uid}`)
        .once('value');
      const userData = snapshot.val();

      // await window.Promise.all(
      //   Object.keys(userData.cuentas).map(async (id) => {
      //     const info = userData.cuentas[id];
      //     const result = await infoAccount(id, info);
      //     info.nameprofile = result ? result.nombre : 'inactivo';
      //     return result;
      //   })
      // );

      if (userData.cuentas === undefined) {
        return '';
      }
      return userData.cuentas;
    } catch (error) {
      //error
    }
  };

  const infoAccount = async (id, info) => {
    const snapshotAccount = await defaultDb
      .ref(`cuentas/${id}/perfiles/${info.perfil}`)
      .once('value');
    const nameAccount = snapshotAccount.val();
    return nameAccount;
  };

  //Perfiles que tiene la cuenta
  const getprofiles = async (currentUser) => {
    try {
      //BdNr basedatos nodoraiz
      const BDNR = await accountID(currentUser);
      const snapshot = await defaultDb.ref(`cuentas/${BDNR}`).once('value');
      const userData = snapshot.val();

      const bdcuentasperfiles = userData.perfiles;
      return bdcuentasperfiles;
    } catch (error) {
      //error
    }
  };

  //Formularios que tiene la cuenta
  const getFormInfo = async () => {
    try {
      const snapshot = await userDB.ref('formularios/').once('value');
      const userData = snapshot.val();

      return userData;
    } catch (error) {
      //error
    }
  };

  //Menú de la cuenta
  const getMenuInfo = async () => {
    try {
      const snapshot = await userDB.ref('menu/').once('value');
      const userData = snapshot.val();

      return userData;
    } catch (error) {
      //error
    }
  };

  // Switch que recibe diferentes proveedores
  const signinwith = async () => {
    const provider = new app.auth.GoogleAuthProvider();
    app.auth().useDeviceLanguage();
    await dbApp.auth().signInWithPopup(provider);
  };

  const recoveryPasword = async (email) => {
    await auth.sendPasswordResetEmail(email);
  };

  const login = async (email, password) => {
    // const response = await auth.signInWithEmailAndPassword(email, password);
    const response = await dbApp
      .auth()
      .signInWithEmailAndPassword(email, password);
    return response;
  };

  const logout = () => {
    // auth.signOut();
    dbApp.auth().signOut();
    setUser(null);
    store.remove('lastAccount');
    setIsAuthenticated(false);
    setMenuConfig({});
  };

  const signup = async (name, email, password) => {
    const response = await auth.createUserWithEmailAndPassword(email, password);

    if (response.user) {
      response.user.updateProfile({ displayName: name });
    }

    await auth.signInWithEmailAndPassword(email, password);

    return response;
  };

  /**
   * Retorna formularios de los agronegocios
   */
  const getForms = async () => {
    const forms = await defaultDb.ref('formularios').once('value');
    const value = forms.val();

    return Object.keys(value).reduce((acc, current) => {
      const item = value[current];

      return {
        ...acc,
        [item.agronegocio]: {
          ...acc[item.agronegocio],
          [item.tamaño]: item.configuracion,
        },
      };
    }, {});
  };

  const updateUser = (userData) => {
    setUser(userData);
  };

  // cambios usuario
  const confirm = async (email, password) => {
    return await auth.signInWithEmailAndPassword(email, password);
  };

  const editname = async (name) => {
    const dataUser = auth.currentUser;
    const displayName = name.nombre;

    await dataUser.updateProfile({ displayName: displayName });

    const changeName = app
      .functions()
      .httpsCallable('change_user_data_config_module');
    const results = await changeName(name);

    return results;
  };

  const editnumber = async (number) => {
    const edituser = auth.currentUser;
    await edituser.updatephoneNumber(number);
  };

  const editemail = async (data) => {
    const edituser = auth.currentUser;
    const correo = edituser.email;
    const newCorreo = data.correo;
    const password = data.password;

    await confirm(correo, password);

    await edituser.updateEmail(newCorreo);

    const changeEmail = app
      .functions()
      .httpsCallable('change_user_data_config_module');

    await changeEmail(data);
  };

  const editpassword = async (newpassword, confirmpassword) => {
    const edituser = auth.currentUser;
    const correo = edituser.email;
    await confirm(correo, confirmpassword);
    await edituser.updatePassword(newpassword);
  };

  /**
   * Creación de Empresa
   * @param {Object} companyData
   */

  const searchUser = async (data) => {
    const busqueda = app.functions().httpsCallable('search_email_admin_module');
    const results = await busqueda(data);
    return results;
  };

  //celular desde bd
  const celBD = async (uid) => {
    const snapshot = await defaultDb.ref(`usuarios/${uid}`).once('value');
    const searchUserDB = snapshot.val();
    return searchUserDB;
  };

  const addUserExist = async (userExist) => {
    //add_user_to_account
    const addUser = app.functions().httpsCallable('add_user_to_account');

    const results = await addUser(userExist);

    return results;
  };

  const AdmCreateAccount = async (data) => {
    const addUser = app
      .functions()
      .httpsCallable('create_account_existing_user');

    const results = await addUser(data);

    return results;
  };
  const getBucketFiles = async () => {
    const getStorageImages = async (img) => {
      const url = await storage.child(img).getDownloadURL();
      return url;
    };

    const resultFolder = [];
    await storage.listAll().then(function (res) {
      res.items.forEach(async function (itemRef) {
        const path = await getStorageImages(itemRef.location.path_);
        const meta = await storage.child(itemRef.location.path_).getMetadata();
        const metaTags = meta.customMetadata ? meta.customMetadata.tags : '';
        const infoField = {
          name: itemRef.location.path_,
          searchTerm: itemRef.location.path_ + ' ' + metaTags,
          categori: 'img',
          img: path,
          size: meta.size,
          type: meta.type,
          created: meta.timeCreated,
          updated: meta.updated,
        };
        resultFolder.push(infoField);
      });
    });
    return resultFolder;
  };

  const createFormAdmin = async (datauser) => {
    // if (isDev) {
    //   app.functions().useFunctionsEmulator('http://localhost:4000/');
    // }

    const createForm = app
      .functions()
      .httpsCallable('create_form_admin_module');

    const results = await createForm(datauser);

    return results;
  };

  const editFormAdmin = async (datauser) => {
    // if (isDev) {
    //   app.functions().useFunctionsEmulator('http://localhost:4000/');
    // }

    const editForm = app.functions().httpsCallable('update_form_admin_module');

    const edit = await editForm(datauser);

    return edit;
  };

  const createCompany = async (companyData) => {
    // if (isDev) {
    //   app.functions().useFunctionsEmulator('http://localhost:4000/');
    // }

    const createCompany = app.functions().httpsCallable('createCompany');

    const results = await createCompany(companyData);

    return results;
  };

  const createUserAdm = async (datauser) => {
    // if (isDev) {
    //   app.functions().useFunctionsEmulator('http://localhost:4000/');
    // }
    const createUser = app
      .functions()
      .httpsCallable('create_user_admin_module');

    const results = await createUser(datauser);

    return results;
  };

  const editUserAdm = async (datauser) => {
    // if (isDev) {
    //   app.functions().useFunctionsEmulator('http://localhost:4000/');
    // }
    const editUser = app.functions().httpsCallable('update_user_admin_module');

    const results = await editUser(datauser);

    return results;
  };

  const inactiveAcountF = async (data) => {
    const deleteAcount = app
      .functions()
      .httpsCallable('disable_user_admin_module');
    const inactive = await deleteAcount(data);

    return inactive;
  };
  const createProfileAdmin = async (data) => {
    const createProfile = app
      .functions()
      .httpsCallable('create_profile_admin_module');
    const create = await createProfile(data);

    return create;
  };

  const editProfileAdmin = async (data) => {
    const editProfile = app
      .functions()
      .httpsCallable('update_profile_admin_module');
    const edit = await editProfile(data);
    return edit;
  };

  const getCalendarUrl = async (data) => {
    const getCalendarCallable = app
      .functions()
      .httpsCallable('get_calendar_url');
    return await getCalendarCallable(data);
  };

  const getAnalyticsUrl = async () => {
    let analyticsUrl = '';

    if (
      user &&
      menuConfig.menuAnalytics &&
      user.superset_activo &&
      user.superset_url !== ''
    ) {
      const token = await dbApp
        .auth()
        .currentUser.getIdToken()
        .catch(function (error) {});
      analyticsUrl = `${user.superset_url}login?token=${token}`;
    }

    return analyticsUrl;
  };

  const getToken = async () => {
    const token = await dbApp
      .auth()
      .currentUser.getIdToken()
      .catch(function (error) {});

      return token;
  };

  const callableFunction = async (nameFunction, data) => {
    // Timeout en ms según https://stackoverflow.com/questions/58313719/error-deadline-exceeded-when-calling-a-firebase-callable-cloud-function-onca
    const getResult = app.functions().httpsCallable(nameFunction, {timeout: 10*60*1000});
    return await getResult({ ...data, uid: dbApp.auth().currentUser.uid });
  };

  const generateMFACode = async (data) => {
    const getCode = app.functions().httpsCallable('generate_mfa_code');
    return await getCode(data);
  };
  const validateMFACode = async (data) => {
    const validateCode = app.functions().httpsCallable('validate_mfa_code');
    return await validateCode(data);
  };

    const getAccountById = async (data) => {
      const busqueda = app.functions().httpsCallable('get_account_by_id');
      const results = await busqueda(data);
      return results;
    };

    if (!isInitialized) return null;

    return (
      <FirebaseContext.Provider
        value={{
          user,
          userM,
          isAuthenticated,
          setIsAuthenticated,
          ...menuConfig,
          login,
          logout,
          setUser,
          signup,
          db: userDB,
          storage,
          photoStorage,
          fileStorage,
          downloadsStorage,
          getForms,
          updateUser,
          createCompany,
          signinwith,
          recoveryPasword,
          menuUserAdm,
          getAnalyticsUrl,
          listAccount,
          getprofiles,
          getFormInfo,
          getBucketFiles,
          getMenuInfo,
          searchUser,
          createUserAdm,
          editUserAdm,
          editname,
          editnumber,
          editemail,
          editpassword,
          addUserExist,
          celBD,
          inactiveAcountF,
          userAccounts,
          AdmCreateAccount,
          initialize,
          changeAccountAdm,
          nameAccount,
          setNameAccount,
          createProfileAdmin,
          createFormAdmin,
          editFormAdmin,
          editProfileAdmin,
          getCalendarUrl,
          app,
          dbApp,
          callableFunction,
          firestoredb,
          firestoreTimeStamp,
          generateMFACode,
          validateMFACode,
          handleReauthenticateUser,
          setIsNewUserRegistered,
          isNewUserRegistered,
          getToken,
          getAccountById,
        }}
        {...props}
      />
    );
}

function useFirebase() {
  const context = useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
}

export { FirebaseProvider, useFirebase };
