import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import Loading from 'core/components/Loading';
import { useFirebase } from 'core/context/firebase-context';

import 'core/components/Loading/index.scss';

const Login = lazy(() => import('./pages/Login'));
const Signup = lazy(() => import('./pages/Signup'));
const SignupGoogle = lazy(() => import('./pages/SignupGoogle'));
const Config = lazy(() => import('./pages/Config'));
const Recovery = lazy(() => import('./pages/Recovery'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const EmailVerification = lazy(() =>
  import('./pages/Login/EmailVerification/EmailVerification')
);
const MultiFactorActivation = lazy(() =>
  import('./pages/Login/TwoStepAuthentication/MultiFactorActivation.js')
);
const Action = lazy(() => import('./pages/Login/Action.js'));

const Routes = () => {
  const firebase = useFirebase();

  if (!firebase.isAuthenticated) {
    return (
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/acceder" component={Login} />
            <Route exact path="/registro" component={Signup} />
            <Route exact path="/registrog" component={SignupGoogle} />
            <Route exact path="/recuperar-contraseña" component={Recovery} />
            <Route exact path="/action" component={Action} />
            <Redirect to="/acceder" />
          </Switch>
        </Suspense>
      </Router>
    );
  }

  if (firebase.isAuthenticated && !firebase.user) {
    return <Loading />;
  } else if (firebase.user) {
    return (
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/action" component={Action} />
            <Route path="/configuracion" component={Config} />
            {firebase.user.userForms.length && <Redirect to="/configuracion" />}
            <Route
              path="/activate-multifactor-authentication"
              component={MultiFactorActivation}
            />
            {firebase.mfa_requerido &&
              firebase.user.emailVerified &&
              firebase.user.multiFactor.enrolledFactors.length === 0 && (
                <Redirect to="/activate-multifactor-authentication" />
              )}
            <Route
              exact
              path="/email-verification"
              component={EmailVerification}
            />
            {((firebase.mfa_requerido && !firebase.user.emailVerified) ||
              (!firebase.user.emailVerified &&
                firebase.isNewUserRegistered)) && (
              <Redirect to="/email-verification" />
            )}

            <Route path="/" component={Dashboard} />
          </Switch>
        </Suspense>
      </Router>
    );
  }
};

export default Routes;
